<template lang="pug">
  div
    base-snackbar(
      :errors="stockGetErrors"
    )
    base-snackbar(
      :errors.sync="feedingProgramCategoryPostErrors"
    )
    v-toolbar(
      :color="!$objectEmpty(defaultFieldValues) ? 'yellow darken-3' : 'primary'"
      elevation="0"
      dense
      dark
    )
      span {{ $objectEmpty(defaultFieldValues) ? 'New' : 'Edit' }}

    feeding-program-category-form(
      :column-attributes="columnAttributes"
      :input-fields.sync="inputFields"
      :default-field-values="defaultFieldValues"
      :stock-categories="stockCategoryList"
      :errors="$objectChangeKeyCase(feedingProgramCategoryPostErrors)"
    )

    v-btn.mr-2(
      color="primary"
      dark
      dense
      @click="sendRequestFeedingProgramCategory"
      :loading="feedingProgramCategoryPosting"
    )
      span Save
    v-btn(
      v-if="!$objectEmpty(defaultFieldValues)"
      color="error"
      dark
      dense
      @click="cancelEdit"
    )
      span Cancel
</template>
<script>
import VueRequest from '@/libs/classes/VueRequest.class'
import { postVars, getVars } from '@/libs/api-helper.extra'
import stockCategoryRepository from '@/repositories/stock-category.repository'
import feedingProgramCategoryRepository from '@/repositories/feeding-program-category.repository'

const feedingProgramCategoryFormProps = () => ({
  columnAttributes: {
    id: { hidden: true },
    bodyWeight: { lg: 6 },
    expectedWeight: { lg: 6 },
  },
})

const inputVars = () => ({
  id: null,
  stockCategoryId: null,
  description: null,
  order: null,
  // dayStart: null,
  // dayEnd: null,
  // bodyWeight: null,
  // expectedWeight: null,
})

const getRequestVarNames = baseName => ({
  loading: baseName + 'Getting',
  list: baseName + 'List',
  error: baseName + 'GetErrors',
})

const postRequestVarNames = baseName => ({
  loading: baseName + 'Posting',
  error: baseName + 'PostErrors',
})

export default {
  name: 'FeedingProgramCategoryCreate',
  props: {
    defaultFieldValues: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    feedingProgramCategoryForm: () => import('@/components/forms/FeedingProgramCategoryForm'),
  },
  data: () => ({
    ...feedingProgramCategoryFormProps(),
    inputFields: inputVars(),
    ...getVars('stock-category'),
    ...postVars('feeding-program-category'),
  }),
  created () {
    this.getStockCategories()
    this.showFeedingProgramCategoriesId()
  },
  methods: {
    sendRequestFeedingProgramCategory () {
      const { defaultFieldValues } = this
      if (this.$objectEmpty(defaultFieldValues)) {
        this.storeFeedingProgramCategory()
        return
      }
      this.updateFeedingProgramCategory()
    },
    getStockCategories () {
      if (this.stockCategoryGetting) return
      const requestVarNames = getRequestVarNames('stockCategory')
      const handler = new VueRequest(this, requestVarNames)
      const repository = stockCategoryRepository.index
      handler.execute(repository)
    },
    storeFeedingProgramCategory () {
      if (this.feedingProgramCategoryPosting) return
      const requestVarNames = postRequestVarNames('feedingProgramCategory')
      const handler = new VueRequest(this, requestVarNames, { list: false })
      const repository = feedingProgramCategoryRepository.store
      const data = this.prepareInputFieldToStore()
      handler.execute(repository, [data], this.storeFeedingProgramCategoryStatusOk)
    },
    updateFeedingProgramCategory () {
      if (this.feedingProgramCategoryPosting) return
      const requestVarNames = postRequestVarNames('feedingProgramCategory')
      const handler = new VueRequest(this, requestVarNames, { list: false })
      const repository = feedingProgramCategoryRepository.update
      const { id } = this.defaultFieldValues
      const data = this.prepareInputFieldToStore()
      handler.execute(repository, [id, data], this.updateFeedingProgramCategoryStatusOk)
    },
    updateFeedingProgramCategoryStatusOk () {
      this.clearInputFields()
      this.clearDefaultFieldValues()
    },
    clearDefaultFieldValues () {
      this.$emit('update:defaultFieldValues', {})
    },
    clearInputFields () {
      this.inputFields = inputVars()
    },
    storeFeedingProgramCategoryStatusOk () {
      this.clearInputFields()
    },
    cancelEdit () {
      this.clearInputFields()
      this.clearDefaultFieldValues()
    },
    prepareInputFieldToStore () {
      const { inputFields } = this
      const result = this.$objectFilterKeys(inputFields, ['id'])
      return this.$objectChangeKeyCase(result, 'camelToSnakeCase')
    },
  },
}
</script>