import searchDelay from '@/libs/searchDelay.extra'
import { errorHandler } from '@/libs/api-helper.extra'

export default class VueGetApi {
  constructor (Vue, { loading = null, list = null, error = null }, conditions = {}) {
    const {
      hasLoading = true,
      hasList = true,
      hasError = true,
    } = conditions
    this.Vue = Vue
    this.setDelay()
    if (hasLoading) this.setLoadingVariableName(loading)
    if (hasList) this.setListVariableName(list)
    if (hasError) this.setErrorVariableName(error)
  }

  setDelay () {
    this.delay = searchDelay()
  }

  setListVariableName (name) {
    this.listVariable = name
  }

  setErrorVariableName (name) {
    this.errorVariable = name
  }

  setLoadingVariableName (name) {
    this.loadingVariable = name
  }

  setLoading (isloading = true) {
    this.Vue[this.loadingVariable] = isloading
  }

  setList (data) {
    this.Vue[this.listVariable] = data
  }

  setErrors (e) {
    this.Vue[this.errorVariable] = errorHandler(e)
  }

  clearErrors () {
    this.Vue[this.errorVariable] = []
  }

  execute (repository = () => {}, args = [], statusOkCallback = () => {}, statusErrorCallback = () => {}, afterRequestCallback = () => {}) {
    this.setLoading(true)
    this.clearErrors()
    this.delay(() => {
      repository(...args)
        .then(response => {
          if (this.listVariable) this.setList(response.data)
          statusOkCallback(response)
        })
        .catch(e => {
          if (this.errorVariable) this.setErrors(e)
          statusErrorCallback(e)
        })
        .then(() => {
          if (this.loadingVariable) this.setLoading(false)
          afterRequestCallback()
        })
    })
  }
}